import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8793b67e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1044c727 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _ed411bb0 = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _fd83926c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0517ddfe = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _1d403a92 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _3e2e4f88 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _c7edd5b0 = () => interopDefault(import('../pages/shaker.vue' /* webpackChunkName: "pages/shaker" */))
const _13c76508 = () => interopDefault(import('../pages/spotlight.vue' /* webpackChunkName: "pages/spotlight" */))
const _f428848a = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _0a5e59a6 = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _430fb483 = () => interopDefault(import('../pages/auth/verify-email.vue' /* webpackChunkName: "pages/auth/verify-email" */))
const _7b10e4f8 = () => interopDefault(import('../pages/author/top-rated.vue' /* webpackChunkName: "pages/author/top-rated" */))
const _3fe9255c = () => interopDefault(import('../pages/book/most-reading.vue' /* webpackChunkName: "pages/book/most-reading" */))
const _dfaba6e2 = () => interopDefault(import('../pages/book/most-wanted.vue' /* webpackChunkName: "pages/book/most-wanted" */))
const _5028f362 = () => interopDefault(import('../pages/book/top-rated.vue' /* webpackChunkName: "pages/book/top-rated" */))
const _1d765d40 = () => interopDefault(import('../pages/game/most-popular.vue' /* webpackChunkName: "pages/game/most-popular" */))
const _38363b8e = () => interopDefault(import('../pages/game/over-years.vue' /* webpackChunkName: "pages/game/over-years" */))
const _830cd416 = () => interopDefault(import('../pages/game/release-calendar.vue' /* webpackChunkName: "pages/game/release-calendar" */))
const _411ffeaa = () => interopDefault(import('../pages/game/top-rated.vue' /* webpackChunkName: "pages/game/top-rated" */))
const _56e9cb0a = () => interopDefault(import('../pages/movie/most-popular.vue' /* webpackChunkName: "pages/movie/most-popular" */))
const _5a5e93d8 = () => interopDefault(import('../pages/movie/over-years.vue' /* webpackChunkName: "pages/movie/over-years" */))
const _118d0ebf = () => interopDefault(import('../pages/movie/release-calendar.vue' /* webpackChunkName: "pages/movie/release-calendar" */))
const _12167721 = () => interopDefault(import('../pages/movie/top-rated.vue' /* webpackChunkName: "pages/movie/top-rated" */))
const _015423b0 = () => interopDefault(import('../pages/tv/most-popular.vue' /* webpackChunkName: "pages/tv/most-popular" */))
const _48b2ddfe = () => interopDefault(import('../pages/tv/over-years.vue' /* webpackChunkName: "pages/tv/over-years" */))
const _582ea465 = () => interopDefault(import('../pages/tv/release-calendar.vue' /* webpackChunkName: "pages/tv/release-calendar" */))
const _749d503b = () => interopDefault(import('../pages/tv/top-rated.vue' /* webpackChunkName: "pages/tv/top-rated" */))
const _5bc4ae1b = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _3a16bdfc = () => interopDefault(import('../pages/author/_slug.vue' /* webpackChunkName: "pages/author/_slug" */))
const _0bc04463 = () => interopDefault(import('../pages/book/_slug/index.vue' /* webpackChunkName: "pages/book/_slug/index" */))
const _619f837c = () => interopDefault(import('../pages/discover/_media/index.vue' /* webpackChunkName: "pages/discover/_media/index" */))
const _350db928 = () => interopDefault(import('../pages/game/_slug/index.vue' /* webpackChunkName: "pages/game/_slug/index" */))
const _085bd462 = () => interopDefault(import('../pages/movie/_slug/index.vue' /* webpackChunkName: "pages/movie/_slug/index" */))
const _b5c63368 = () => interopDefault(import('../pages/person/_slug.vue' /* webpackChunkName: "pages/person/_slug" */))
const _36de6208 = () => interopDefault(import('../pages/tv/_slug/index.vue' /* webpackChunkName: "pages/tv/_slug/index" */))
const _01825e30 = () => interopDefault(import('../pages/user/_username/index.vue' /* webpackChunkName: "pages/user/_username/index" */))
const _b72aa7aa = () => interopDefault(import('../pages/book/_slug/recommendations.vue' /* webpackChunkName: "pages/book/_slug/recommendations" */))
const _5dbba8ec = () => interopDefault(import('../pages/book/_slug/reviews.vue' /* webpackChunkName: "pages/book/_slug/reviews" */))
const _2be313f4 = () => interopDefault(import('../pages/game/_slug/recommendations.vue' /* webpackChunkName: "pages/game/_slug/recommendations" */))
const _a68e2c96 = () => interopDefault(import('../pages/game/_slug/reviews.vue' /* webpackChunkName: "pages/game/_slug/reviews" */))
const _2a24ed2c = () => interopDefault(import('../pages/movie/_slug/recommendations.vue' /* webpackChunkName: "pages/movie/_slug/recommendations" */))
const _bc9995aa = () => interopDefault(import('../pages/movie/_slug/reviews.vue' /* webpackChunkName: "pages/movie/_slug/reviews" */))
const _0374e8f8 = () => interopDefault(import('../pages/tv/_slug/recommendations.vue' /* webpackChunkName: "pages/tv/_slug/recommendations" */))
const _4493f145 = () => interopDefault(import('../pages/tv/_slug/reviews.vue' /* webpackChunkName: "pages/tv/_slug/reviews" */))
const _b47d8836 = () => interopDefault(import('../pages/user/_username/check-later.vue' /* webpackChunkName: "pages/user/_username/check-later" */))
const _99e013c4 = () => interopDefault(import('../pages/user/_username/items.vue' /* webpackChunkName: "pages/user/_username/items" */))
const _099763b6 = () => interopDefault(import('../pages/user/_username/lists/index.vue' /* webpackChunkName: "pages/user/_username/lists/index" */))
const _2598a80c = () => interopDefault(import('../pages/user/_username/network.vue' /* webpackChunkName: "pages/user/_username/network" */))
const _9b0b2758 = () => interopDefault(import('../pages/user/_username/ratings.vue' /* webpackChunkName: "pages/user/_username/ratings" */))
const _93d56906 = () => interopDefault(import('../pages/user/_username/stats.vue' /* webpackChunkName: "pages/user/_username/stats" */))
const _2cb63132 = () => interopDefault(import('../pages/user/_username/statuses.vue' /* webpackChunkName: "pages/user/_username/statuses" */))
const _7e8b2d9f = () => interopDefault(import('../pages/tv/_slug/season/_season/index.vue' /* webpackChunkName: "pages/tv/_slug/season/_season/index" */))
const _081248c4 = () => interopDefault(import('../pages/user/_username/lists/_id.vue' /* webpackChunkName: "pages/user/_username/lists/_id" */))
const _49713328 = () => interopDefault(import('../pages/tv/_slug/season/_season/reviews.vue' /* webpackChunkName: "pages/tv/_slug/season/_season/reviews" */))
const _1be5fad6 = () => interopDefault(import('../pages/tv/_slug/season/_season/episode/_episode/index.vue' /* webpackChunkName: "pages/tv/_slug/season/_season/episode/_episode/index" */))
const _0964e2c2 = () => interopDefault(import('../pages/tv/_slug/season/_season/episode/_episode/reviews.vue' /* webpackChunkName: "pages/tv/_slug/season/_season/episode/_episode/reviews" */))
const _c717f4a0 = () => interopDefault(import('../pages/discover/_media/_property/_value/index.vue' /* webpackChunkName: "pages/discover/_media/_property/_value/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _8793b67e,
    name: "about"
  }, {
    path: "/auth",
    component: _1044c727,
    name: "auth"
  }, {
    path: "/discover",
    component: _ed411bb0,
    name: "discover"
  }, {
    path: "/faq",
    component: _fd83926c,
    name: "faq"
  }, {
    path: "/feedback",
    component: _0517ddfe,
    name: "feedback"
  }, {
    path: "/news",
    component: _1d403a92,
    name: "news"
  }, {
    path: "/privacy",
    component: _3e2e4f88,
    name: "privacy"
  }, {
    path: "/shaker",
    component: _c7edd5b0,
    name: "shaker"
  }, {
    path: "/spotlight",
    component: _13c76508,
    name: "spotlight"
  }, {
    path: "/terms",
    component: _f428848a,
    name: "terms"
  }, {
    path: "/auth/change-password",
    component: _0a5e59a6,
    name: "auth-change-password"
  }, {
    path: "/auth/verify-email",
    component: _430fb483,
    name: "auth-verify-email"
  }, {
    path: "/author/top-rated",
    component: _7b10e4f8,
    name: "author-top-rated"
  }, {
    path: "/book/most-reading",
    component: _3fe9255c,
    name: "book-most-reading"
  }, {
    path: "/book/most-wanted",
    component: _dfaba6e2,
    name: "book-most-wanted"
  }, {
    path: "/book/top-rated",
    component: _5028f362,
    name: "book-top-rated"
  }, {
    path: "/game/most-popular",
    component: _1d765d40,
    name: "game-most-popular"
  }, {
    path: "/game/over-years",
    component: _38363b8e,
    name: "game-over-years"
  }, {
    path: "/game/release-calendar",
    component: _830cd416,
    name: "game-release-calendar"
  }, {
    path: "/game/top-rated",
    component: _411ffeaa,
    name: "game-top-rated"
  }, {
    path: "/movie/most-popular",
    component: _56e9cb0a,
    name: "movie-most-popular"
  }, {
    path: "/movie/over-years",
    component: _5a5e93d8,
    name: "movie-over-years"
  }, {
    path: "/movie/release-calendar",
    component: _118d0ebf,
    name: "movie-release-calendar"
  }, {
    path: "/movie/top-rated",
    component: _12167721,
    name: "movie-top-rated"
  }, {
    path: "/tv/most-popular",
    component: _015423b0,
    name: "tv-most-popular"
  }, {
    path: "/tv/over-years",
    component: _48b2ddfe,
    name: "tv-over-years"
  }, {
    path: "/tv/release-calendar",
    component: _582ea465,
    name: "tv-release-calendar"
  }, {
    path: "/tv/top-rated",
    component: _749d503b,
    name: "tv-top-rated"
  }, {
    path: "/user/settings",
    component: _5bc4ae1b,
    name: "user-settings"
  }, {
    path: "/author/:slug?",
    component: _3a16bdfc,
    name: "author-slug"
  }, {
    path: "/book/:slug",
    component: _0bc04463,
    name: "book-slug"
  }, {
    path: "/discover/:media",
    component: _619f837c,
    name: "discover-media"
  }, {
    path: "/game/:slug",
    component: _350db928,
    name: "game-slug"
  }, {
    path: "/movie/:slug",
    component: _085bd462,
    name: "movie-slug"
  }, {
    path: "/person/:slug?",
    component: _b5c63368,
    name: "person-slug"
  }, {
    path: "/tv/:slug",
    component: _36de6208,
    name: "tv-slug"
  }, {
    path: "/user/:username",
    component: _01825e30,
    name: "user-username"
  }, {
    path: "/book/:slug?/recommendations",
    component: _b72aa7aa,
    name: "book-slug-recommendations"
  }, {
    path: "/book/:slug?/reviews",
    component: _5dbba8ec,
    name: "book-slug-reviews"
  }, {
    path: "/game/:slug?/recommendations",
    component: _2be313f4,
    name: "game-slug-recommendations"
  }, {
    path: "/game/:slug?/reviews",
    component: _a68e2c96,
    name: "game-slug-reviews"
  }, {
    path: "/movie/:slug?/recommendations",
    component: _2a24ed2c,
    name: "movie-slug-recommendations"
  }, {
    path: "/movie/:slug?/reviews",
    component: _bc9995aa,
    name: "movie-slug-reviews"
  }, {
    path: "/tv/:slug?/recommendations",
    component: _0374e8f8,
    name: "tv-slug-recommendations"
  }, {
    path: "/tv/:slug?/reviews",
    component: _4493f145,
    name: "tv-slug-reviews"
  }, {
    path: "/user/:username?/check-later",
    component: _b47d8836,
    name: "user-username-check-later"
  }, {
    path: "/user/:username?/items",
    component: _99e013c4,
    name: "user-username-items"
  }, {
    path: "/user/:username?/lists",
    component: _099763b6,
    name: "user-username-lists"
  }, {
    path: "/user/:username?/network",
    component: _2598a80c,
    name: "user-username-network"
  }, {
    path: "/user/:username?/ratings",
    component: _9b0b2758,
    name: "user-username-ratings"
  }, {
    path: "/user/:username?/stats",
    component: _93d56906,
    name: "user-username-stats"
  }, {
    path: "/user/:username?/statuses",
    component: _2cb63132,
    name: "user-username-statuses"
  }, {
    path: "/tv/:slug?/season/:season",
    component: _7e8b2d9f,
    name: "tv-slug-season-season"
  }, {
    path: "/user/:username?/lists/:id",
    component: _081248c4,
    name: "user-username-lists-id"
  }, {
    path: "/tv/:slug?/season/:season?/reviews",
    component: _49713328,
    name: "tv-slug-season-season-reviews"
  }, {
    path: "/tv/:slug?/season/:season?/episode/:episode",
    component: _1be5fad6,
    name: "tv-slug-season-season-episode-episode"
  }, {
    path: "/tv/:slug?/season/:season?/episode/:episode?/reviews",
    component: _0964e2c2,
    name: "tv-slug-season-season-episode-episode-reviews"
  }, {
    path: "/discover/:media/:property/:value",
    component: _c717f4a0,
    name: "discover-media-property-value"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
